var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "650px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("closeAttach")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONIM099P070.001"))),
        ]),
        _c(
          "div",
          { staticClass: "content_box" },
          [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX060P012.088"))),
            ]),
            _c("DEXT5Upload", {
              ref: "dextupload",
              attrs: {
                id: "dextupload1",
                width: "100%",
                height: "200px",
                "file-info": _vm.uploadFileList,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.insertFile()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONIM099P070.003")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }