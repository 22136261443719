<template>
  <div
    class="popup_wrap"
    style="width:650px;"
  >
    <button
      class="layer_close"
      @click="$emit('closeAttach')"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ONIM099P070.001') }}</h1><!-- 첨부파일 확인 -->
      <div class="content_box">
        <h2 class="content_title">{{ $t('msg.ONEX060P012.088') }}</h2><!-- C/A 요청 첨부 파일 -->
        <DEXT5Upload
          ref="dextupload"
          id="dextupload1"
          width="100%"
          height="200px"
          :file-info="uploadFileList"
        />
      </div>
      <div class="mt10 text_center">
        <a
          class="button blue lg"
          href="#"
          @click.prevent="insertFile()"
        >{{ $t('msg.ONIM099P070.003') }}</a><!-- Save -->
      </div>
    </div>
  </div>
</template>

<script>

import ca from '@/api/rest/trans/ca'
import DEXT5Upload from '@/components/DEXT5Upload'

export default {
  name: 'CARequestAttachPop',
  components: { DEXT5Upload },
  props: {
    blNo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      params: {
        blNo: '',
        // 첨부파일 리스트(실제 입력)
        uploadFileInfos: []
      },

      // Dext Uploader와 연결되어 있는 배열
      uploadFileList: []
    }
  },
  created () {
    this.params.blNo = this.blNo
    this.initPopup()
  },
  methods: {
    async initPopup () {
      await this.findCaRequestAttach()
    },
    // 파일 조회
    async findCaRequestAttach () {
      await ca.findCaRequestAttach(this.params.blNo).then(response => {
        // console.log(response)
        if (response.headers.respcode === 'C0000') {
          let fileList = response.data.caRequestFileList
          // console.log('getPaymentAttachList >>> fileList')
          // console.log(fileList)

          if (fileList !== undefined) {
            fileList.forEach(file => {
              this.uploadFileList.push(
                {
                  guid: file.reqRno + '_' + file.fileSeq,
                  originalName: file.fileNm,
                  size: file.fileSz,
                  fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?reqRno=' + file.reqRno + '&atchFileSeq=' + file.fileSeq + '&type=CA_REQ_ATTACH',

                  reqRno: file.reqRno,
                  atchFileSeq: file.fileSeq
                }
              )
            })
          }
        }
      })
    },
    // 저장 or 수정
    async insertFile () {
      // 저장하시겠습니까
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONIM099P070.004'), useConfirmBtn: true })) {
        this.onConfirmInsert()
      }
    },
    // 저장 Confirm '확인' 클릭 시 콜백 메소드
    onConfirmInsert () {
      // 상위 컴포넌트에서 가져온 데이터를 실제 입력할 parameter에 담는다.
      // this.params = { ...this.params, ...this.parentInfo }
      // 실제 입력할 파일리스트를 담는다.
      this.params.uploadFileInfos = this.uploadFileList

      // 새로 추가된 파일은 json에 'newFlag' 값을 부여해준다. ('new'는 뒷단에서 예약어 이므로 새로 추가)
      this.params.uploadFileInfos.map((item) => (item.newFlag = item.new))

      // 기존 파일은 fileId에 다운로드 링크가 들어 있어서
      // 뒷단에서 Data binding 할 때, 오류가 나서 값을 비워줘야 한다.
      this.params.uploadFileInfos.forEach(element => {
        if (element.newFlag === undefined || element.newFlag === '') {
          element.fileId = ''
        }
      })

      const TH = this

      // 파일 저장
      ca.insertCaRequestAttach(this.params).then(async (response) => {
        // console.log(response)
        const result = response.data
        if (response.headers.respcode === 'C0000' && result !== undefined && result.resultCode === 'SUCC') {
          // 저장되었습니다.
          if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONIM099P070.005') })) {
            TH.$emit('closeAttach', TH.uploadFileList.length)
          }
        } else {
          // 저장에 실패하였습니다. 관리자에게 문의해주시기 바랍니다.
          TH.$ekmtcCommon.alertDefault(TH.$t('msg.ONIM099P070.006'))
        }
      })
    }
  }
}
</script>
